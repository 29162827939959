// @flow
import React from 'react'

import ProfileCard from 'src/components/ProfileCard'
import PageRow from 'src/common/components/PageRow'
import Carousel from 'src/components/Carousel'

import styles from './Testimonials.module.scss'

type Testimonial = {|
  emoji?: string,
  image?: string,
  testimonial: string,
  author: string,
  position: string,
|}

type Props = {|
  content: {|
    heading: string,
    image: string,
    list: Array<Testimonial>,
  |},
|}

const MAX_TESTIMONIAL_NUMBER = 12

export default function Testimonials({
  content: { heading, image, list },
}: Props) {
  return (
    <PageRow className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.header}>{heading}</div>
        <img src={image} />
        <Carousel>
          {list.splice(0, MAX_TESTIMONIAL_NUMBER).map(item => (
            <ProfileCard
              key={item.author}
              name={item.author}
              imageUrl={item.image}
              emoji={item.emoji}
              title={item.position}
              description={item.testimonial}
            />
          ))}
        </Carousel>
      </div>
    </PageRow>
  )
}
