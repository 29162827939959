//@flow
import * as React from 'react'

import Image from 'src/common/components/Image'

import styles from './ProfileCard.module.scss'

type Props = {|
  imageUrl?: string,
  name: string,
  title: string,
  description: string,
  emoji?: string,
|}

export default function ProfileCard({
  imageUrl,
  name,
  title,
  description,
  emoji,
}: Props) {
  return (
    <div className={styles.container}>
      {emoji ? (
        <span className={styles.emoji}>{emoji}</span>
      ) : (
        <div className={styles.image}>
          <Image src={imageUrl} alt={name} widths={{ desktop: 60 }} />
        </div>
      )}

      <div>
        <div className={styles.name}>{name} </div>
        <div className={styles.title}>{title}</div>
      </div>
      <div className={styles.description}>{description}</div>
    </div>
  )
}
